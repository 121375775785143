// @flow
import React from 'react';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import { graphql } from 'gatsby';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
  },
};

class Blog_2014_08_01_Kanarra_Creek extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="2CII" title="Kanarra Creek" />
        <p>
          My family took a trip to the Grand Canyon. We had a few hours set
          aside on the drive down to visit Kanarra Creek.
        </p>
        <p>
          This was my first time to visit a{' '}
          <GlossaryLink>slot canyon</GlossaryLink> with my wife and I couldn’t
          have been happier. The canyon is family friendly – I carried my 2 year
          old and my wife was 4 months pregnant at the time.
        </p>
        <p>My wife took most of these and did the editing for all of them.</p>
        <Image
          caption="Hike into the slot canyon. The first of many water crossings we had to do."
          image={data._1}
        />
        <Image
          caption={
            <>
              Still on the <GlossaryLink>approach</GlossaryLink>. The log
              Savannah is standing on is an old ladder that was probably used to
              get up the lower falls and had washed downstream during a{' '}
              <GlossaryLink>flash flood</GlossaryLink>.
            </>
          }
          image={data._2}
        />
        <Image
          caption="Lower waterfall. I walked up and down this with my oldest on my back."
          image={data._3}
        />
        <Image
          caption="William peeking over my shoulder at the lower waterfall."
          image={data._4}
        />
        <Image
          caption="Small cascade in an open section between the upper and lower falls. We had lunch here and watched William play in the cold water."
          image={data._5}
        />
        <Image
          caption="Upper waterfall. This is as far as we got."
          image={data._6}
        />
        <Image
          caption="One member of our group passed out from exhaustion – good time to turn around."
          image={data._7}
        />
        <Image
          caption="Top of the lower waterfall on the way back."
          image={data._8}
        />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2014-08-01-kanarra-creek')(
  Blog_2014_08_01_Kanarra_Creek
);

export const query = graphql`
  query {
    _1: file(
      relativePath: { eq: "images/blog/2014-08-01-kanarra-creek/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: { eq: "images/blog/2014-08-01-kanarra-creek/2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: { eq: "images/blog/2014-08-01-kanarra-creek/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: { eq: "images/blog/2014-08-01-kanarra-creek/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: { eq: "images/blog/2014-08-01-kanarra-creek/5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: { eq: "images/blog/2014-08-01-kanarra-creek/6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: { eq: "images/blog/2014-08-01-kanarra-creek/7.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: { eq: "images/blog/2014-08-01-kanarra-creek/8.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
